import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import blogStyle from "../components/blog.module.scss"
import { BlogPosts } from "../components/blogposts"

import { languageContext } from '../context/LanguageContext'
import { pathToSeoTitle, orderPostsByUserLanguages } from "../utils.tsx"

export const query = graphql`
query ($tags: [String]!) {
  allMarkdownRemark(filter: {fields: {tags: {in: $tags}}}) {
    nodes {
      fields {
        slug
      }
      frontmatter {
        title
        date
        tags
        lang
        description
      }
    }
  }
}
`

const Tags = (props) => {
  const listTags = props.data.allMarkdownRemark.nodes.flatMap((node) => node.frontmatter.tags)
  const freqObjTags = listTags.reduce(
    (acc, e) => { acc[e] = (acc[e] || 0) + 1; return acc; },
    {}
  );
  
  const freqListTags = Object.keys(freqObjTags).map((key) => {
    return {count: freqObjTags[key], tag: key}
  })

  const sortedTags = freqListTags.sort((a, b) =>  b.count - a.count) // decending order

  // console.log("Shorted tags: ", JSON.stringify(sortedTags))

  for (var i = 1; i < sortedTags.length; i++) {
    if (sortedTags[i].count !== sortedTags[0].count) {
      break;
    }
  }
  const topTags = sortedTags.slice(0, i)
  const otherTags = sortedTags.slice(i)

  // console.log("Top tags:  ", JSON.stringify(topTags))
  // console.log("Rest tags: ", JSON.stringify(otherTags))
  
  const linkToTags = (tagElements) => tagElements
    .map((elem) => { 
      return (
        <span key={elem.tag}>
          <Link to={`/blog/tag/${elem.tag}`} key={elem.tag}>{elem.tag}</Link>
          ({elem.count})
        </span>
      ) 
    })
    .reduce((prev, curr) => [prev, ', ', curr])

  const topTagLinks = linkToTags(topTags)
  const otherTagLinks = (() => {
    if (otherTags.length > 0) {
      return (
        <span key={"other"}>
          <br/>
          Other: {linkToTags(otherTags)}
        </span>
      );
    }
  })();

  const { userLanguages } = React.useContext(languageContext);

  const orderedNodes = orderPostsByUserLanguages(
    props.data.allMarkdownRemark.nodes, 
    userLanguages || ["en"]
  );

  return (
    <Layout>
      <SEO title={pathToSeoTitle(props.path)} />
      <h1>Blog by Tag</h1>
      <p className={blogStyle.blogPostMeta}>Main: {topTagLinks}{otherTagLinks}</p>
      <br/>
      {orderedNodes
        .map(([lang, nodes]) => {
          return (
            <BlogPosts 
              lang={lang}
              nodes={nodes}
            />
          )
        })
      }
    </Layout>
  )
}

export default Tags
